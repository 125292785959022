import React, {useEffect} from "react"
import {graphql} from "gatsby"
import {window, document} from "browser-monads"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from "../components/home/carousel"
import Purpose from "../components/home/purpose"
import Philosophy from "../components/home/philosophy"
import Pillars from "../components/home/pillars"
import Compass from "../components/home/compass"

export const query = graphql`
query {
  sanityHome {
    slides {
      author
      largeText
      image {
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
    }
    purpose {
      images {
        asset {
          fluid(maxWidth: 1080) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      smallText
      text
    }
    philosophy {
      title
      text
      image {
        asset {
          fluid(maxWidth: 2040) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
    }
    origin {
      title
      image {
        asset {
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      _rawText
    }
    interdependence {
      title
      image {
        asset {
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      _rawText
    }
    coexistence {
      title
      image {
        asset {
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      _rawText
    }
    progress {
      title
      image {
        asset {
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      _rawText
    }
    integrity {
      title
      image {
        asset {
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      _rawText
    }
    wisdom {
      title
      image {
        asset {
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      _rawText
    }
    compass {
      quote
      quoteAuthor
      earth {
        title
        _rawText
        author
        image {
          asset {
            fluid(maxWidth: 1080) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
      self {
        title
        _rawText
        author
        image {
          asset {
            fluid(maxWidth: 1080) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
      community {
        title
        _rawText
        author
        image {
          asset {
            fluid(maxWidth: 1080) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
    }
  }
}
`

const Home = ({data}) => {
  const
    {slides, purpose, philosophy, origin, interdependence, integrity, coexistence, progress, wisdom, compass} = data.sanityHome,
    pillars = [origin, interdependence, coexistence, progress, integrity, wisdom];
    // purposeImages = document.querySelector(".background-images");

  useEffect(() => {
    window.onscroll = () => {
      // if(purposeImages) {
      //   purposeImages.style.transform = `translate3d(-${10 * t1}%, 0, 0)`
      // }
      Array.prototype.forEach.call(document.querySelectorAll(".purpose-container"), (function(e) {
        if (e.getBoundingClientRect().top < window.innerHeight && e.getBoundingClientRect().bottom > 0) {
          let t1 = Math.round(1e5 * (((window.scrollY || window.pageYOffset) - window.innerHeight) / window.innerHeight * 2 + 3)) / 1e6;
          e.querySelector(".background-images").style.transform = `translate3d(-${10 * t1}%, 0, 0)`
        }
      }))

      Array.prototype.forEach.call(document.querySelectorAll(".home--philosophy-image"), (function(e) {
        if (e.getBoundingClientRect().top < window.innerHeight && e.getBoundingClientRect().bottom > 0) {
          let t = Math.round(1e5 * ((-e.getBoundingClientRect().top - window.innerHeight) / window.innerHeight * 2 + 3)) / 1e5;
          e.querySelector(".philosophy-wrapper").style.transform = `translate3d(0, ${5 * t}%, 0) scale(1.1)`
        }
      }))
    }
  })

  return(
    <Layout>
      <SEO title="Home" />

      <Slider slides={slides}/>

      <Purpose purpose={purpose}/>

      <Philosophy philosophy={philosophy}/>

      <Pillars pillars={pillars}/>

      <Compass compass={compass}/>

    </Layout>
  )
}

export default Home
