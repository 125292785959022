import React from "react"
import {Link} from "gatsby"

import Image from "../../components/image"
import CTAarrow from "../../assets/svg/ubuntu-arrow-cta.svg"


const Philosophy = ({philosophy}) => {
  const {image, text, title} = philosophy

  return(
    <React.Fragment>
      <div className="grid-container dark-background home--philosophy">
        <h2 className="heading" data-sal="slide-up" data-sal-delay="150" data-sal-duration="1000" data-sal-easing="ease">Philosophy</h2>
        <h3 data-sal="slide-up" data-sal-delay="150" data-sal-duration="1000" data-sal-easing="ease">{title}</h3>
        <div className="text-container">
          <p data-sal="slide-right" data-sal-delay="150" data-sal-duration="1000" data-sal-easing="ease">{text}</p>
          <div className="cta-container" data-sal="slide-right" data-sal-delay="150" data-sal-duration="1000" data-sal-easing="ease">
            <Link to="/services">WHAT WE DO</Link>
            <Link to="/services"><CTAarrow className="clickable"/></Link>
          </div>
        </div>
      </div>
      <div className="home--philosophy-image">
        <Image className="philosophy-wrapper" fluid={image.asset.fluid} alt="Experience Ubuntu Philosophy"/>
      </div>
    </React.Fragment>
  )
}

export default Philosophy
