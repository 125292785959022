import React, {useState} from "react"
import {Link} from "gatsby"
import classNames from "classnames"

import Image from "../../components/image"
import CTAarrow from "../../assets/svg/ubuntu-arrow-cta.svg"
import BlockContent from "../../components/block-content"

const Compass = ({compass}) => {
  const
    {community, earth, self, quote, quoteAuthor} = compass,
    [current, setCurrent] = useState("");

    let
    titleClass = classNames({
      'pillar-active-title': current !== null
    }),
    textClass = classNames({
      'pillar-active-text description': current !== null
    });

  const getCurrent = (current) => {
    if (current === "self") {
      return self
    } else if (current === "earth") {
      return earth
    } else if (current === "community") {
      return community
    }
  }

  return(
    <div className="home--compass">
      <h2 className="heading" data-sal="slide-up" data-sal-delay="150" data-sal-duration="1000" data-sal-easing="ease">Compass</h2>
      <h3 data-sal="slide-up" data-sal-duration="700" data-sal-easing="ease">{quote}</h3>
      <p data-sal="slide-up" data-sal-duration="700" data-sal-easing="ease">- {quoteAuthor} -</p>

      <div className="info-container grid-container">
        <div className="circles">
          <div className="circles-child" onMouseEnter={() => setCurrent("self")} onMouseLeave={() => setCurrent("")}>
            <Image className="compass--image" fluid={self.image.asset.fluid} alt="Experience Ubuntu Self"/>
            <h3>{self.title}</h3>
          </div>
          <div className="circles-child" onMouseEnter={() => setCurrent("earth")} onMouseLeave={() => setCurrent("")}>
            <Image className="compass--image" fluid={earth.image.asset.fluid} alt="Experience Ubuntu Earth"/>
            <h3>{earth.title}</h3>
            </div>
          <div className="circles-child" onMouseEnter={() => setCurrent("community")} onMouseLeave={() => setCurrent("")}>
            <Image className="compass--image" fluid={community.image.asset.fluid} alt="Experience Ubuntu Community"/>
            <h3>{community.title}</h3>
          </div>
        </div>
        <div className="text-container">
          {current !== "" && (
            <React.Fragment>
              <h3 className={titleClass}>{getCurrent(current).title}</h3>
              <BlockContent className={textClass} blocks={getCurrent(current)._rawText || []}/>
              {!!getCurrent(current).author && (
                <p className={`${textClass} author`}>- {getCurrent(current).author} -</p>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
      <div className="cta-container" data-sal="slide-right" data-sal-duration="1000" data-sal-easing="ease">
        <Link to="/services">Explore what we do</Link>
        <Link to="/services"><CTAarrow className="clickable"/></Link>
      </div>
    </div>
  )
}

export default Compass
