import React, {useState} from "react"
import BlockContent from "../block-content"
import classNames from "classnames"

import Image from "../../components/image"
import ArrowLeft from "../../assets/svg/ubuntu-arrow-left.svg"
import ArrowRight from "../../assets/svg/ubuntu-arrow-right.svg"

const Pillars = ({pillars}) => {
  const
    [current, setCurrent] = useState(null),
    [currentMobile, setCurrentMobile] = useState(0);

  let
    imageClass = classNames({
      'pillar-active': current !== null
    }),
    titleClass = classNames({
      'pillar-active-title': current !== null
    }),
    mobileImage = classNames({
      'slide-current': currentMobile !== null
    });

  const getTitle = currentMobile => {
    switch (currentMobile) {
      case 0:
        return "Origin"
      case 1:
        return "Interdependence"
      case 2:
        return "Coexistence"
      case 3:
        return "Progress"
      case 4:
        return "Integrity"
      default:
        return "Wisdom"
    }
  }

  const getNewCurrent = newCurrentMobile => {
    switch (newCurrentMobile) {
      case -1:
        return setCurrentMobile(5)
      case 6:
        return setCurrentMobile(0)
      default:
        return setCurrentMobile(newCurrentMobile)
    }
  }

  return(
    <React.Fragment>
      <h2 className="heading pillars" data-sal="slide-up" data-sal-delay="150" data-sal-duration="1000" data-sal-easing="ease">The six pillars</h2>
      <div className="home--pillars">
        {/* BIG SCREEN */}
        <div className="images-container grid-container">
          {current !== null ? (
            <Image className={imageClass} fluid={pillars[current].image.asset.fluid}/>
          ) : (
            <div style={{visibility: "hidden"}}>
              <Image fluid={pillars[0].image.asset.fluid} alt={`Experience Ubuntu Pillars: ${getTitle}`}/>
            </div>
          )}
        </div>
        <div className="text-container grid-container">
          <div className="pillars-names">
            <h3 onMouseEnter={() => setCurrent(0)} onMouseLeave={() => setCurrent(null)} data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000" data-sal-easing="ease">Origin</h3>
            <h3 onMouseEnter={() => setCurrent(1)} onMouseLeave={() => setCurrent(null)} data-sal="slide-up" data-sal-delay="100" data-sal-duration="1050" data-sal-easing="ease">Interdependence</h3>
            <h3 onMouseEnter={() => setCurrent(2)} onMouseLeave={() => setCurrent(null)} data-sal="slide-up" data-sal-delay="100" data-sal-duration="1100" data-sal-easing="ease">Coexistence</h3>
            <h3 onMouseEnter={() => setCurrent(3)} onMouseLeave={() => setCurrent(null)} data-sal="slide-up" data-sal-delay="100" data-sal-duration="1150" data-sal-easing="ease">Progress</h3>
            <h3 onMouseEnter={() => setCurrent(4)} onMouseLeave={() => setCurrent(null)} data-sal="slide-up" data-sal-delay="100" data-sal-duration="1200" data-sal-easing="ease">Integrity</h3>
            <h3 onMouseEnter={() => setCurrent(5)} onMouseLeave={() => setCurrent(null)} data-sal="slide-up" data-sal-delay="100" data-sal-duration="1250" data-sal-easing="ease">Wisdom</h3>
          </div>
          <div className="pillars-info">
            {current !== null && (
              <React.Fragment>
                <h4 className={titleClass}>{pillars[current].title}</h4>
                <BlockContent className="description" blocks={pillars[current]._rawText || []}/>
              </React.Fragment>
            )}
          </div>
        </div>
        {/* END BIG SCREEN */}

        {/* SMALL SCREEN */}
        <div className="images-container-mobile" data-sal="fade" data-sal-delay="150" data-sal-duration="500" data-sal-easing="ease">
          <Image className={mobileImage} fluid={pillars[currentMobile].image.asset.fluid} alt={`Experience Ubuntu Pillars: ${getTitle}`}/>
        </div>
        <div className="pillars-names-mobile">
          <h3 data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000" data-sal-easing="ease">{getTitle(currentMobile)}</h3>
          <div className="controls">
            <ArrowLeft className="clickable" onClick={() => getNewCurrent(currentMobile - 1)} />
              <span>{currentMobile + 1} - 6</span>
            <ArrowRight className="clickable" onClick={() => getNewCurrent(currentMobile + 1)}/>
          </div>
        </div>
        <div className="pillars-info-mobile">
            <h4>{pillars[currentMobile].title}</h4>
          <BlockContent className="description" blocks={pillars[currentMobile]._rawText || []}/>
        </div>
        {/* END SMALL SCREEN */}
      </div>
    </React.Fragment>
  )
}

export default Pillars
