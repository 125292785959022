import React from "react"
import { Link } from "gatsby"

import Image from "../../components/image"
import CTAarrow from "../../assets/svg/ubuntu-arrow-cta.svg"

const Purpose = ({purpose}) => {
  const {images, text, smallText} = purpose

  return(
    <div className="home--purpose">
      <div className="purpose-container">
        <div className="background-images">
          {images.map((image, i) => {
            return(
              <div className="img-container" key={i * 10000}>
                <Image className="purpose-wrapper" fluid={image.asset.fluid} alt="Experience Ubuntu Purpose"/>
              </div>
            )
          })}
        </div>
        <div className="heading-container" data-sal="fade" data-sal-delay="150" data-sal-duration="1100" data-sal-easing="ease">
          <h2>Purpose</h2>
        </div>
      </div>
      <div className="text-container grid-container">
        <h1 id="purpose-text" data-sal="slide-up" data-sal-delay="150" data-sal-duration="1000" data-sal-easing="ease">{text}</h1>
        <div className="smallText-container">
          <p id="purpose-smallText" data-sal="slide-right" data-sal-delay="150" data-sal-duration="1000" data-sal-easing="ease">{smallText}</p>
          <div className="cta-container" data-sal="slide-right" data-sal-delay="150" data-sal-duration="1000" data-sal-easing="ease">
            <Link to="/team">Who we are</Link>
            <Link to="/kaleidoscope/coming-home"><CTAarrow className="clickable"/></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Purpose
