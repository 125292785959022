import React, {useState, useEffect} from "react"
import classNames from "classnames"

import Image from "../../components/image"
import ArrowLeft from "../../assets/svg/ubuntu-arrow-left.svg"
import ArrowRight from "../../assets/svg/ubuntu-arrow-right.svg"

const Slider = ({slides}) => {
  const
    [state, setState] = useState(0),
    [time, setTime] = useState("clear")

  const getClasses = (i, type) => {
    let
      classes = classNames({
        'slide-wrapper': type === 'slide',
        'slide-current': type === 'slide' && i === state,
        'phrase-wrapper': type === 'quote',
        'phrase-current': type === 'quote' && i === state
      });
    return classes
  }

  const getSlide = (newState) => {
    setTime("set")
    switch (newState) {
      case 3:
        return setState(0)
      case 4:
        return setState(1)
      default:
        return setState(newState)
    }
  }

  useEffect(() => {
    if(time === "clear") {
      setTimeout(() => {
        getSlide(state + 1)
      }, 6000);
    } else if(time === "set"){
      setTimeout(() => {
        setTime("clear")
      }, 3500);
    }
  }, [time])

  return(
    <div className="home--carousel">
      <div className="image-container">
        <div className="image-slider">
          {slides.map((slide, i) => {
            return (
              <div key={i * 100} className={getClasses(i, 'slide')}>
                <Image fluid={slide.image.asset.fluid} alt="Experience Ubuntu, Yoga, Consulting"/>
              </div>
            )
          })}
        </div>
      </div>
      <div className="quote-container">
        <div className="quote-slider">
          {slides.map((slide, i )=> {
            return (
              <div key={i * 10} className={getClasses(i, 'quote')}>
                <p className="phrase">{slide.largeText}</p>
                <p className="quote-author">- {slide.author} -</p>
              </div>
            )
          })}
        </div>
        <div className="arrow-container">
          <ArrowLeft className="clickable" onClick={() => getSlide(state + 2)} />
          <ArrowRight className="clickable" onClick={() => getSlide(state + 1)}/>
        </div>
      </div>
    </div>
  )
}

export default Slider
